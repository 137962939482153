import _ from 'lodash';
import '../styles/main.scss';

import 'jquery';
import '../scripts/jquery.scrollTo.min.js';
// import '../scripts/analytics_wp.js';
import '../scripts/modal.js';
// import '../scripts/boblayer.js';
// import '../scripts/redirect.js';
// import '../scripts/bottomize.js';
// import '../scripts/bobform_access.js';
import '../scripts/accessform_wp.js';
import '../scripts/main_wp.js';
import '../scripts/spinner.js';

// load scripts form libs
// import '../../../libs/scripts/3_bob/spinner.js';

// import 'bootstrap';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/index';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';

