// source file: scripts/accessform.js

// const env = process.env.NODE_ENV;
// console.log(env);


(function ($) {

  $.fn.accessForm = function () {

    var msg = null;

    var successTimeout = null;

    var modalMsgHide = function (duration) {
      if(!duration) {
        duration = 0;
      }
      $('.modal-msg').fadeOut(duration, function () {
        $('.modal-msg .msg-content').hide();
      });
      msg = null;
    };

    var modalMsgSuccess = function () {
      msg = 'success';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-success').show();
      $('.modal-msg').fadeIn();
      successTimeout = window.setTimeout(modalMsgHide, 3000, 300);
      modalHide();
    };

    var modalHide = function () {
      e = $.Event('hide.bob.modal');
      $('.modal-form-context.modal').trigger(e);
      $('.modal-form-context.modal').fadeOut(400, function() {
        $(this).find('.modal-form-context .m-content').html('');
      });
      $('body').removeClass('modal-bob-open');
    };

    var modalMsgError = function () {
      msg = 'error';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-error').show();
      $('.modal-msg').fadeIn();
    };

    var sendData = function (e) {

      e.preventDefault();

      $('.form-group input', '.modal').removeClass('is-invalid');

      modalMsgHide();

      $('#submit', '.modal form.form-access').prop('disabled', true);

      var data = {};
      var label = {};
      $(':input', e.target).each(function() {
        data[this.name] = $(this).val();
        label[this.name] = $("label[for='"+$(this).attr('id')+"']", e.target).text();
      });

      var notValid = false;

      if (data.description.trim() === "") {
        $('.form-group-description textarea', '.modal').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-description textarea', '.modal').removeClass('is-invalid');
      }

      for(var i=3; i>0; i--) {
        if ($('#checkOption' + i).is(':checked')) {
          data.description = $('#checkOption' + i).parent('label').text() + "\n\n" + data.description;
        }
      }

      if (data.position.trim() === "") {
        $('.form-group-position input', '.modal').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-position input', '.modal').removeClass('is-invalid');
        data.description = label.position + ": " + data.position + "\n\n" + data.description;
      }
      if (data.organisation.trim() === "") {
        $('.form-group-organisation input', '.modal').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-organisation input', '.modal').removeClass('is-invalid');
        data.description = label.organisation + ": " + data.organisation + "\n\n" + data.description;
      }
      if (data.country.trim() === "") {
        $('.form-group-country input', '.modal').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-country input', '.modal').removeClass('is-invalid');
        data.description = label.country + ": " + data.country + "\n\n" + data.description;
      }
      if (data.phone.trim() === "") {
        // $('.form-group-phone input', '.modal').addClass('is-invalid');
        // notValid = true;
      } else {
        $('.form-group-phone input', '.modal').removeClass('is-invalid');
        data.description = label.phone + ": " + data.phone + "\n\n" + data.description;
      }
      if (data.email.trim() === "") {
        $('.form-group-email input', '.modal').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-email input', '.modal').removeClass('is-invalid');
        data.description = label.email + ": " + data.email + "\n\n" + data.description;
      }
      if (data.name.trim() === "") {
        $('.form-group-name input', '.modal').addClass('is-invalid');
        notValid = true;
      } else {
        $('.form-group-name input', '.modal').removeClass('is-invalid');
        data.description = label.name + ": " + data.name + "\n\n" + data.description;
      }

      if (notValid) {
        // enable button again
        $('#submit', '.modal form.form-access').prop('disabled', false);
        return;
      }

      $('document').spinner('show');

      $.ajax({
        url: "/zen",
        type: "POST",
        data: data,
        cache: false,
        success: function (response) {
          modalMsgSuccess();

          // console.log(response);

          // google tag event
          window.dataLayer.push({"event": "conversionAccess"});

          // enable button again
          $('#submit', '.modal form.form-access').prop('disabled', false);
          $('document').spinner('hide');

        },
        error: function (xhr, b, x) {
          var responseText = xhr.responseText;
          if(responseText && responseText.indexOf('{"error":"invalid email"}') > -1) {
            $('.form-group-email input', '.modal').addClass('is-invalid');
          }
          // else if(responseText && responseText.indexOf('{"error":"RecordInvalid"}') > -1) {
          //   $('.form-control.name').parent('.form-group').addClass('has-error');
          //   $('.form-control.text').parent('.form-group').addClass('has-error');
          // }
          else {
            modalMsgError();
          }
          // enable button again
          $('#submit', '.modal form.form-access').prop('disabled', false);
          $('document').spinner('hide');
        }
      });

    };

    $('.modal').on('hide.bob.modal', function() {
      if (msg === 'error') {
        modalMsgHide(300);
      }
    });

    $(document).off('.accessform');

    $(document).on('submit.accessform', '.modal form.form-access', function (e) {
      sendData(e);
    });

    $(document).on('click.accessform', '.modal-msg', function () {
      modalMsgHide(300);
    });

    return this;

  };

}(jQuery));
